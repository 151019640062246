<template>
  <v-dialog
    v-model="toggle"
    scrollable
    transition="slide-y-reverse-transition"
    style="max-height: 90vh !important;"
    light
    fullscreen
  >
    <v-card class="terms white">
      <v-btn
        absolute
        top
        right
        fab
        icon
        class="mt-8 mr-n4"
        @click="close"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
      <v-card-text 
        class="py-4 grey--text text--darken-3"
      >
        <icon-base
          width="37"
          height="43"
          name="hive"
          class="hive mt-4 mb-8 black--text"
          style="height: 32px;"
        >
          <brand-symbol />
        </icon-base>
        
        <h2 class="title text-h6 my-4 grey--text text--darken-4">
          Termos e Condições do Programa de Motoristas Mobees
        </h2>
        <p>
          Estes Termos e Condições regem o Programa de Motoristas Mobees (“<span style="text-decoration:underline;">Programa</span>”), disponibilizado pela <strong>MOBEES ON WHEELS BRASIL LTDA.</strong>, sociedade com sede na Ladeira da Glória, nº 26, Bloco 3, 2º Pavimento, Glória, no Município do Rio de Janeiro, Estado do Rio de Janeiro, CEP 22.211-120, inscrita no CNPJ/ME sob o nº 35.711.113/0001-80 (“<span style="text-decoration:underline;">Mobees</span>”), e contratado pelo(a) motorista de táxi, devidamente regularizado(a) para o exercício da profissão, ou motorista de transporte remunerado privado de passageiros ou entregas, pessoa física, previamente aprovado(a) pela Mobees, que se encontra devidamente qualificado(a) no Termo de Adesão assinado eletronicamente nesta data, na página eletrônica da Mobees na rede mundial de computadores (“<span style="text-decoration:underline;">Contratante</span>”).
        </p>
        <p>
          O Programa foi concebido para viabilizar a exposição de conteúdo publicitário desenvolvido por empresas que mantêm relacionamento comercial com a Mobees (“<span style="text-decoration:underline;">Clientes Mobees</span>”), nas cidades, em painéis digitais inteligentes, instalados nos veículos operados por motoristas de táxi devidamente regularizados para o exercício da profissão, ou por motoristas que participem costumeiramente de serviços de transporte remunerado privado de passageiros ou entregas ofertados por uma das organizações elencadas no Anexo I destes Termos e Condições, desde que, em qualquer caso, tais motoristas preencham todos os requisitos do Programa enumerados no Anexo II destes Termos e Condições, por ocasião de sua adesão ao Programa ou durante todo o prazo em que permanecer a ele vinculado (doravante designados, indistintamente, como “<span style="text-decoration:underline;">Motoristas Elegíveis</span>”).
        </p>
        <p>
          <strong>AO FIRMAR O TERMO DE ADESÃO, O CONTRATANTE DECLARARÁ CUMPRIR TODOS OS REQUISITOS DO PROGRAMA ELENCADOS NO ANEXO I, ABAIXO, E TER LIDO, COMPREENDIDO E ACEITO ESTES TERMOS E CONDIÇÕES, BEM COMO ESTARÁ ASSUMINDO TODAS AS OBRIGAÇÕES ORA PACTUADAS. PORTANTO, É FUNDAMENTAL A LEITURA ATENTA DESTES TERMOS E CONDIÇÕES, ANTES DA ASSINATURA DO TERMO DE ADESÃO.</strong>
        </p>
        <p>
          A MOBEES RESERVA PARA SI O DIREITO DE REALIZAR ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES NESTES TERMOS E CONDIÇÕES, A QUALQUER MOMENTO, SEM A NECESSIDADE DE AVISO PRÉVIO AO CONTRATANTE. EM CASO DE ALTERAÇÕES DESTES TERMOS E CONDIÇÕES QUE RESTRINJAM DIREITOS DO CONTRATANTE, A MOBEES PODERÁ COMUNICAR TAL ALTERAÇÃO AO CONTRATANTE, AO SEU EXCLUSIVO CRITÉRIO, POR QUALQUER MEIO DE COMUNICAÇÃO DISPONÍVEL, SEJA FÍSICO OU ELETRÔNICO. TODAVIA, ESTA LIBERALIDADE NÃO AFASTA A RESPONSABILIDADE DO CONTRATANTE DE VERIFICAR PERIODICAMENTE EVENTUAIS MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES, BEM COMO NÃO AFETA O DIREITO DO CONTRATANTE DE SE DESLIGAR DO PROGRAMA A QUALQUER MOMENTO.
        </p>
        <p>
          CASO O CONTRATANTE NÃO CONCORDE COM ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES FUTURAS DESTES TERMOS E CONDIÇÕES, DEVERÁ CANCELAR PRONTAMENTE A SUA PARTICIPAÇÃO NO PROGRAMA E RESTITUIR A POSSE DOS EQUIPAMENTOS PARA A MOBEES, NA FORMA PREVISTA NESTES TERMOS E CONDIÇÕES. O FATO DE O CONTRATANTE CONTINUAR A UTILIZAR OS EQUIPAMENTOS APÓS A DIVULGAÇÃO OU COMUNICAÇÃO DE UMA MODIFICAÇÃO DOS TERMOS E CONDIÇÕES REPRESENTA O SEU CONSENTIMENTO EM MANTER-SE A ESTES TERMOS E CONDIÇÕES, TAL COMO ALTERADOS.
        </p>
        <p>
          A MOBEES PODERÁ IMEDIATAMENTE ENCERRAR O PROGRAMA EM RELAÇÃO AO CONTRATANTE OU DE MODO GERAL, NO TODO OU EM PARTE, A QUALQUER MOMENTO E POR QUALQUER MOTIVO, DE MODO QUE NÃO HAVERÁ QUALQUER GARANTIA DE CONTINUIDADE OU PERÍODO MÍNIMO DE DURAÇÃO NO PROGRAMA.
        </p>
        <p>
          AS PARTES ACEITAM E RECONHECEM COMO PLENAMENTE VÁLIDA E EFICAZ QUALQUER FORMA DE CELEBRAÇÃO DO TERMO DE ADESÃO E DESTES TERMOS E CONDIÇÕES, BEM COMO DE SUAS ALTERAÇÕES FUTURAS, POR MEIOS ELETRÔNICOS, INDEPENDENTEMENTE DA PRESENÇA DE TESTEMUNHAS, COM O USO DE SISTEMAS DE ASSINATURA ELETRÔNICA, QUE NÃO UTILIZAM UM CERTIFICADO DIGITAL ICP-BRASIL, NOS QUAIS A COMPROVAÇÃO DA ASSINATURA É FEITA POR MEIO DE EVIDÊNCIAS COLETADAS JUNTO ÀS PARTES SIGNATÁRIAS, NOS TERMOS DO ARTIGO 10, § 2º, DA MEDIDA PROVISÓRIA Nº 2200-2.
        </p>

        <ol>
          <li>
            <strong>CONSIDERAÇÕES PRELIMINARES</strong>  

            <ol>
              <li>
                O Programa é disponibilizado ao Contratante, levando em consideração que:

                <ol type="i">
                  <li>
                    a Mobees é empresa que se dedica exclusivamente às atividades de agenciamento de espaços para publicidade, exceto em veículos de comunicação, e de consultoria em publicidade e em gestão empresarial, de maneira que a Mobees não desempenha atividades de transporte de passageiros ou de cargas, em qualquer modalidade, ou atividades conexas;
                  </li>

                  <li>
                    a Mobees é proprietária de equipamentos eletrônicos, <em>hardwares, </em>programas, aplicativos, redes e sistemas informatizados, destinados à exposição de conteúdo publicitário desenvolvido pelos Clientes Mobees nas cidades;
                  </li>

                  <li>
                    o Programa oferece aos Motoristas Elegíveis que assinarem o Termo de Adesão uma oportunidade de receber pagamentos da Mobees à título de contraprestação pela veiculação de conteúdo publicitário dos Clientes Mobees, em seus respectivos veículos, de acordo com estes Termos e Condições; e
                  </li>

                  <li>
                    mediante a assinatura do Termo de Adesão, o Contratante declara que se qualifica como um Motorista Elegível e manifesta o seu desejo de aderir a estes Termos e Condições e, assim, receber um Equipamento em comodato e a ser instalado pela Mobees em seu veículo, permitindo que o mesmo exiba anúncios e outros conteúdos publicitários dos Clientes Mobees, selecionados exclusivamente pela Mobees, pelo que o Contratante fará jus aos pagamentos na forma aqui prevista.
                  </li>

                  <li>
                    os anúncios são divididos em indicativos, publicitários, culturais e de utilidade pública. Os anúncios indicativos têm o objetivo de informar a atividade que é exercida pela Mobees. Anúncios publicitários são aqueles nos quais a mensagem oferece comercialmente um serviço, um produto ou uma comunicação institucional, sendo pagos pelos Clientes Mobees. Os anúncios culturais divulgam os eventos que estão ocorrendo no município. Anúncios de utilidade pública divulgam informações úteis dos órgãos oficiais. Os anúncios culturais e de utilidade pública serão na maioria das vezes pro bono, salvo caso em que sejam classificados como publicitários.
                  </li>
                </ol>
              </li>

              <li>
                NÃO SE ESTABELECE, POR FORÇA DESTES TERMOS E CONDIÇÕES OU DA ADESÃO DO CONTRATANTE AO PROGRAMA, QUALQUER VÍNCULO EMPREGATÍCIO, DE ASSOCIAÇÃO OU DE REPRESENTAÇÃO COMERCIAL ENTRE A MOBEES E O CONTRATANTE, CABENDO A CADA UMA DAS PARTES O CUMPRIMENTO DE SUAS RESPECTIVAS OBRIGAÇÕES TRABALHISTAS, SOCIAIS E PREVIDENCIÁRIAS, NA FORMA DA LEGISLAÇÃO EM VIGOR. 
              </li>
              <li>
                Declaram as Partes que estes Termos e Condições são firmados nos limites de sua função social e pelos princípios da lealdade e boa-fé objetiva. Declaram, ainda, o seu expresso consentimento quanto às cláusulas e condições do Programa e que não implicam em lesão de qualquer direito, tal qual previsto no artigo 157 da Lei nº 10.406, de 10 de janeiro de 2002, conforme alterada (Código Civil Brasileiro).
              </li>
            </ol>
          </li>

          <li>
            <strong>OBJETO</strong> 

            <ol>
              <li>
                Estes Termos e Condições, o Termo de Adesão, o Manual de Instruções dos Equipamentos (como definido adiante) e quaisquer outros documentos acessórios relacionados à participação do Contratante no Programa compõem um contrato vinculativo, firmado entre a Mobees e o Contratante que engloba, essencialmente, os seguintes negócios jurídicos: (i) comodato de 1 (um) painel digital inteligente (conectável a outros dispositivos ou redes por meio de protocolos sem fio), de suporte customizado e de outros equipamentos da Mobees utilizados para instalação e conexão do painel ao veículo do Contratante (“<span style="text-decoration:underline;">Equipamentos</span>”); e (ii) cessão de espaço no veículo do Contratante para instalação dos Equipamentos e veiculação de conteúdo publicitário desenvolvido pelos Clientes Mobees, selecionado exclusivamente pela Mobees, mediante o pagamento da remuneração do Programa ao Contratante.
              </li>
            </ol>
          </li>

          <li>
            <strong>DECLARAÇÕES DO CONTRATANTE, REQUISITOS DO PROGRAMA E DADOS CADASTRAIS</strong>

            <ol>
              <li>
                Neste ato, o Contratante declara e garante à Mobees, como condições essenciais para a sua adesão e manutenção no Programa, que:
                <ol>
                  <li>
                    é indivíduo maior de 21 (vinte e um) anos de idade, plenamente capaz para a assunção das obrigações previstas nestes Termos e Condições, bem como para o gozo de seus direitos civis, em geral;
                  </li>

                  <li>
                    participa costumeiramente de serviços de transporte remunerado privado de passageiros ou entregas ofertados por uma das organizações elencadas no Anexo I destes Termos e Condições;
                  </li>

                  <li>
                    cumpre e cumprirá todos os requisitos do Programa, enumerados no Anexo II destes Termos e Condições (“<span style="text-decoration:underline;">Requisitos</span>”), de forma cumulativa, contínua e ininterrupta, durante todo o período em que permanecer no Programa;
                  </li>

                  <li>
                    não foi suspenso ou excluído anteriormente do Programa;
                  </li>

                  <li>
                    não está cumprindo pena de suspensão do direito de dirigir ou de cassação da Carteira Nacional de Habilitação, decorrente de crime de trânsito, e não está impedido, judicialmente, de exercer seus direitos;
                  </li>

                  <li>
                    entende que o Contratante não é sócio, franqueado, parceiro, agente ou funcionário da Mobees e entende que estes Termos e Condições apenas criam um relacionamento de contratante e contratada independente, e não de empregador e empregado;
                  </li>

                  <li>
                    deseja e pretende continuar operando como motorista independente e garante que tem se envolvido habitualmente no negócio de fornecer serviços de direção e/ou transporte, de forma independente;
                  </li>

                  <li>
                    reconhece que não tem e não terá poder ou autoridade para incorrer em qualquer dívida, obrigação ou responsabilidade em nome da Mobees;
                  </li>

                  <li>
                    reconhece que, sob nenhuma circunstância, a participação do Contratante no Programa lhe dará direito a cobertura nos planos de assistência social, médica ou odontológica da Mobees; planos de seguro de vida ou invalidez; planos de compra de participação ou qualquer outra forma de remuneração em participação; ou quaisquer outros benefícios adicionais que possam ser patrocinados pela Mobees ou ofertados aos colaboradores da Mobees;
                  </li>

                  <li>
                    entende e concorda que o Contratante determinará o método, detalhes e meios de desempenho das suas atividades profissionais que guardam relação com o Programa, de modo que a Mobees não tem o direito de, e não irá, controlar a maneira ou determinar o método de desempenho das atividades profissionais do Contratante, incluindo a quantidade de tempo que o Contratante despende na direção;
                  </li>

                  <li>
                    se a qualquer momento o Contratante acreditar que seu relacionamento com a Mobees tem natureza diferente de um relacionamento de contratada independente, concorda em notificar imediatamente a Mobees sobre este entendimento, por escrito;
                  </li>

                  <li>
                    leu e compreendeu estes Termos e Condições, esclareceu quaisquer dúvidas surgidas em relação às condições aqui estabelecidas e, feito isso, concordou em assumir todas as obrigações aqui previstas; e
                  </li>

                  <li>
                    seu registro e participação no Programa estão em conformidade com todas e quaisquer leis, regulamentos, decisões judiciais, contratos e outros acordos aplicáveis, dos quais o Contratante seja parte ou aos quais o Contratante esteja sujeito, conforme o caso.
                  </li>
                </ol>
              </li>

              <li>
                Caso o Contratante deixe de cumprir os Requisitos, a qualquer momento, este se tornará inelegível para o Programa, pelo que estes Termos e Condições serão automaticamente rescindidos. Neste caso, caberá ao Contratante notificar prontamente a Mobees sobre o fato e, no prazo e na forma previstos no item 4.4, abaixo, providenciar a devolução dos Equipamentos recebidos em comodato.  
                <ol>
                  <li>
                    Periodicamente, por ocasião de vistorias dos Equipamentos ou de outros eventos relacionados à manutenção dos Equipamentos, o Contratante deverá assinar termos de declaração confirmando o atendimento dos Requisitos do Programa e a validade e veracidade das declarações e garantias descritas no item 3.1, acima. 
                  </li>
                </ol>
              </li>

              <li>
                Durante todo o período de sua participação no Programa, o Contratante deverá manter atualizadas todos os dados cadastrais informados no Termo de Adesão. Assim, o Contratante deverá notificar a Mobees sobre qualquer alteração de tais dados cadastrais, no prazo de até 10 (dez) dias a contar de sua ocorrência.  
              </li>
              
              <li>
                Quaisquer notificações do Contratante à Mobees relacionadas ao Programa deverão ser (i) enviadas por correio eletrônico (<em>e-mail</em>), a partir do endereço eletrônico do Contratante informado no Termo de Adesão, para o endereço da Mobees: contato@mobees.com.br; (ii) enviadas por meio do canal de comunicações disponível na página eletrônica da Mobees na <em>internet</em>: http:www.mobees.com.br; ou (iii) entregues, por escrito, mediante protocolo de recebimento, na sede da Mobees (“<span style="text-decoration:underline;">Canais de Comunicação</span>”).
              </li>
            </ol>
          </li>

          <li>
            <strong>COMODATO DOS EQUIPAMENTOS</strong> 
            <ol>
              <li>
                A Mobees concorda em ceder em comodato os Equipamentos identificados no Anexo III, a serem instalados no veículo do Contratante, a fim de permitir que este participe do Programa. 
              </li>
              
              <li>
                A instalação, a manutenção e a remoção dos Equipamentos serão realizadas exclusivamente pela equipe técnica da Mobees, em uma das bases da Mobees. Caberá exclusivamente ao Contratante o encargo de levar o seu veículo até uma das bases da Mobees, para fins de instalação, manutenção ou remoção dos Equipamentos, conforme previsto nestes Termos e Condições ou sempre que necessário, sendo vedado ao Contratante efetuar, diretamente ou por intermédio de terceiros, qualquer modificação, violação ou intervenção nos Equipamentos, fora de uma das bases da Mobees e/ou sem a assistência da equipe técnica da Mobees. 
              </li>
              
              <li>
                O Contratante reconhece e concorda que os Equipamentos contêm mecanismos de rastreamento que permitem à Mobees determinar a localização geográfica dos Equipamentos, de modo que a Mobees poderá monitorar e registrar a localização dos Equipamentos o tempo todo, enquanto o Contratante permanecer no Programa.  
                <ol>
                  <li>
                    O Contratante reconhece e concorda, ainda, que os Equipamentos consomem energia gerada pela bateria do veículo, sendo certo que o uso incorreto dos Equipamentos poderá causar danos à bateria. Em hipótese alguma, tais danos serão reparados pela Mobees ou às suas expensas. 
                  </li>
                </ol>
              </li>

              <li>
                Todos os Equipamentos e os direitos autorais dos <em>softwares</em> e sistemas instalados nos painéis inteligentes são e permanecerão como sendo de domínio e propriedade única e exclusiva da Mobees, de modo que o comodato limita-se à entrega dos Equipamentos para os fins do Programa, não compreendendo qualquer transferência de tecnologia, de propriedade intelectual ou industrial dos Equipamentos ou dos <em>softwares</em> e sistemas nele instalados. 
              </li>
              
              <li>
                O comodato dos Equipamentos será automaticamente extinto por ocasião do término da participação do Contratante no Programa, seja em virtude da resilição voluntária destes Termos e Condições, por qualquer das Partes, da rescisão destes Termos e Condições por falta imputável ao Contratante ou por qualquer outro motivo. Assim, no prazo de até 2 (dois) dias após o término da participação do Contratante no Programa, o Contratante deverá agendar visita a uma das bases da Mobees, por meio dos Canais de Comunicação, para remoção e devolução dos Equipamentos do Programa. Caso o agendamento não seja realizado por qualquer motivo, então o Contratante ficará obrigado a levar o seu veículo diretamente até uma das bases da Mobees, independentemente de agendamento, no prazo de até 5 (cinco) dias após o término da sua participação no Programa. O não comparecimento do Contratante a uma das bases da Mobees, na data agendada ou, conforme o caso, após o 5º (quinto) dia contado do término da participação do Contratante no Programa, sujeitará o Contratante ao pagamento de uma multa diária não compensatória à Mobees, desde já fixada em R$ 250,00 (duzentos e cinquenta reais) por dia de atraso na devolução dos Equipamentos, sem prejuízo do pagamento das perdas e danos porventura causados à Mobees e da adoção de todas as medidas legais cabíveis para a retomada da posse dos Equipamentos.  
              </li>
              
              <li>
                Sem prejuízo das demais obrigações previstas neste Termos e Condições, são obrigações do Contratante, na qualidade de comodatário dos Equipamentos: 
                <ol>
                  <li>
                    utilizar os Equipamentos exclusivamente para os fins do Programa, conforme previsto nestes Termos e Condições, que é a única forma de uso compatível com sua natureza e com o fim a que se destina, devendo tratar os Equipamentos com o mesmo cuidado e diligência com que toda pessoa ativa e proba trata os seus próprios bens; 
                  </li>

                  <li>
                    manter vigilância, zelar pela guarda e preservação dos Equipamentos em nome da Mobees, até o término de sua participação no Programa e a devolução dos equipamentos em uma das bases da Mobees;
                  </li>

                  <li>
                    manter os Equipamentos nas mesmas condições em que foram entregues ao Contratante, sujeito ao desgaste do uso normal e adequado dos Equipamentos;
                  </li>

                  <li>
                    seguir rigorosamente o Manual de Instruções dos Equipamentos, disponível na página eletrônica da Mobees, que declaro ter acessado, lido e compreendido todos os seus termos, bem como as demais instruções que forem passadas ao Contratante no momento da instalação dos Equipamentos, quanto ao seu uso e manutenção;
                  </li>

                  <li>
                    em caso de danos aos Equipamentos, comunicar a Mobees no prazo de até 2 (dois) dias contados da ocorrência do dano, por meio dos Canais de Comunicação, e agendar uma visita às bases da Mobees para reparação dos Equipamentos. Caso o agendamento não seja realizado por qualquer motivo, o Contratante deverá comparecer a uma das bases da Mobees para reparação dos Equipamentos independentemente de agendamento, no prazo de até 5 (cinco) dias contados da ocorrência do dano;
                  </li>

                  <li>
                    em caso de ação ou omissão dolosa ou culposa do Contratante, este deverá responsabilizar-se integralmente por eventuais prejuízos decorrentes da perda, danos ou destruição dos Equipamentos, eximindo a Mobees de qualquer responsabilidade, observado o disposto no item 4.7, abaixo; 
                  </li>

                  <li>
                    não promover qualquer modificação, violação ou intervenção nos Equipamentos, fora de uma das bases da Mobees e/ou sem a assistência técnica da Mobees, sobretudo não interferir, contornar ou desativar qualquer recurso dos Equipamentos ou dos <em>softwares</em> nele instalados, incluindo qualquer mecanismo de segurança ou controle de acesso;
                  </li>

                  <li>
                    não subcomodatar ou ceder os Equipamentos a terceiros, a qualquer título; e 
                  </li>

                  <li>
                    nos termos do item 4.5, acima, comparecer com o seu veículo em uma das bases da Mobees para restituir os Equipamentos à Mobees, após o encerramento de sua participação no Programa, por qualquer motivo, no mesmo estado em que o Contratante recebeu os Equipamentos, exceto pelo desgaste natural decorrente do seu uso normal e adequado, sob pena de aplicação da multa prevista no item 4.7, abaixo.
                  </li>
                  <li>
                    em caso de desinstalação dos Equipamentos por qualquer motivo e por solicitação do Contratante, haverá o prazo de 30 dias para reinstalação. Após o período supracitado, a participação no Programa será encerrada automaticamente, sem necessidade de aviso prévio.
                  </li>
                </ol>
              </li>

              <li>
                Em caso de perda, danos irreparáveis ou destruição dos Equipamentos, a participação do Contratante será automaticamente cancelada e estes Termos e Condições serão rescindidos de pleno direito. Caso o Contratante tenha concorrido com dolo ou culpa para a perda, danificação ou destruição dos Equipamentos, o mesmo pagará à Mobees uma multa compensatória no valor de R$ 5.000,00 (cinco mil reais), reajustável anualmente pela variação acumulada no Índice de Preços ao Consumidor Amplo, apurado e divulgado pelo Instituto Brasileiro de Geografia e Estatística (IPCA), à título de reparação por perdas e danos. A título de exemplo, são listados abaixo alguns eventos que caracterizarão a culpa do Contratante pela perda, danificação ou destruição dos Equipamentos, sem prejuízo de outros eventos que possam ocorrer na prática:
                <ul>
                  <li>
                    inobservância de limites máximos de altura em garagens, pontes, viadutos ou outras passagens;
                  </li>

                  <li>
                    realização de interferências nos Equipamentos por conta própria, fora das bases da Mobees e/ou sem a assistência da equipe técnica da Mobees;
                  </li>

                  <li>
                    utilização de serviços mecanizados de lava-jato para limpeza do veículo com os Equipamentos instalados;
                  </li>

                  <li>
                    utilização de produtos químicos para limpeza dos Equipamentos, em desconformidade com o Manual de Instruções;
                  </li>
                  <li>
                    utilização dos Equipamentos em desconformidade com o Manual de Instruções, em geral.
                  </li>
                </ul>
                <ol>
                  <li>
                    O Contratante concorda que, embora não esteja obrigado a contratar cobertura de seguro específica para os Equipamentos, qualquer indenização por ele recebida junto à companhia seguradora, em virtude da perda ou danos aos Equipamentos ou, se for o caso, que sobejar o valor da indenização pela perda do veículo, será repassada à Mobees, em até 2 (dois) dias após o seu recebimento, na medida do necessário para o pagamento integral da multa compensatória definida no item 4.7, acima.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>CESSÃO DE ESPAÇO PARA VEICULAÇÃO PUBLICITÁRIA</strong> 
            <ol>
              <li>
                O Contratante, neste ato, cede à Mobees espaço localizado na parte externa do teto acima dos passageiros do seu veículo descrito no Termo de Adesão, para instalação dos Equipamentos, incluindo o painel digital publicitário inteligente, com as dimensões descritas no Anexo III (“<span style="text-decoration:underline;">Painel</span>”), o seu suporte e os demais mecanismos e dispositivos necessários para a sua correta instalação e funcionamento. 
              </li>
              
              <li>
                O Painel deverá permanecer ligado enquanto o motor do veículo estiver em funcionamento, cabendo ao Contratante a obrigação de ligar o Painel antes de começar a dirigir o seu veículo. A luminosidade e o conteúdo publicitário exibido no Painel serão controlados automaticamente, ao exclusivo critério da Mobees e de acordo com as características dos Equipamentos, sem a possibilidade de interferência do Contratante.  
              </li>
              
              <li>
                O Painel, os demais Equipamentos e os conteúdos publicitários disponíveis em relação a estes serão fornecidos exclusivamente pela Mobees ao Contratante, conforme disponibilidade, não havendo garantias de qualquer tipo, expressas ou implícitas, relativas à continuidade do Programa, ao correto funcionamento dos Equipamentos e aos conteúdos publicitários disponíveis em relação a estes. Destarte, a Mobees não garante que o funcionamento do Painel e a exibição nele de conteúdo publicitário será ininterrupta, bem como não garante que os Equipamentos estarão livres de erros, vírus e outros componentes prejudiciais, ou mesmo que tais questões, se surgidas, serão resolvidas pela Mobees. 
              </li>
              
              <li>
                O Contratante declara e garante à Mobees que o veículo cujo espaço foi cedido para veiculação publicitária será utilizado ativamente durante toda a participação do Contratante no Programa, de modo que o veículo seja guiado nas cidades atendidas pelo Programa por, no mínimo, 160 (cento e sessenta) horas a cada período de 1 (um) mês, sempre com o Painel ligado (“<span style="text-decoration:underline;">Exposição Mínima</span>”). O Contratante declara, ainda, reconhecer que o não atendimento da Exposição Mínima impactará a remuneração do Programa, conforme previsto na cláusula sexta, abaixo.  
              </li>
              
              <li>
                Durante todo o tempo em que estiver operando o seu veículo com o Painel instalado ou participando de atividades relacionadas ao Programa, o Contratante deverá observar as seguintes normas de conduta:
                <ol>
                  <li>
                    não depreciará a Mobees, os Clientes Mobees e parceiros ou o Programa;
                  </li>

                  <li>
                    manterá comportamento profissional e diligente, refletindo favoravelmente a boa-fé e a reputação da Mobees, dos Clientes Mobees e parceiros;
                  </li>

                  <li>
                    obedecerá a todas as exigências legais e regulatórias, sobretudo aquelas previstas em normas referente à atividade de transporte remunerado privado de passageiros, de transporte de passageiros de utilidade pública, incluindo as leis, regulamentos e demais normas de trânsito aplicáveis, em âmbito federal, estadual e municipal, tanto quanto ao Contratante como em relação ao veículo utilizado para o Programa;
                  </li>

                  <li>
                    guiará o veículo com urbanidade, dando tratamento cortês, profissional e respeitoso a todas as pessoas, sem qualquer tipo de discriminação;
                  </li>

                  <li>
                    responsabilizar-se-á integralmente pela prestação do serviço de transporte de pessoas; e
                  </li>

                  <li>
                    cumprirá todos os Requisitos do Programa.
                  </li>
                </ol>
              </li>

              <li>
                O descumprimento das normas de conduta descritas no item 5.5 acima ensejará a rescisão destes Termos e Condições e o término do Programa para o Contratante.
              </li>
            </ol>
          </li>

          <li>
            <strong>REMUNERAÇÃO</strong> 

            <ol>
              <li>
                Como contrapartida por sua participação no Programa e pela cessão de espaço no seu veículo para divulgação de conteúdo publicitário dos Clientes Mobees, o Contratante, após cumprir todos os Requisitos e a Exposição Mínima, passará a fazer jus ao recebimento de pagamentos mensais, de valor variável.
              </li>

              <li>
                O valor variável é determinado em função do faturamento mensal da Mobees, em cada mês. 20% (vinte por cento) do faturamento mensal será destinado para divisão entre os Contratantes que cumprirem todos os Requisitos e a Exposição Mínima. Fará jus a esse valor o Contratante no Programa que atingir pelo menos o objetivo mínimo de exposição.
              </li>

              <li>
                Os pagamentos da remuneração do Programa serão realizados no dia 10 do mês subsequente ao mês em que foi realizada a exposição mínima do Programa, de acordo com os itens 6.1 e 6.2. Se o Contratante deixar o Programa antes de completar o primeiro mês, por qualquer motivo, então o Contratante não fará jus a qualquer remuneração proporcional. Da mesma forma, o Contratante não fará jus ao recebimento de qualquer remuneração nos períodos mensais em que o Contratante deixar de cumprir a Exposição Mínima, conforme registrado pelo Painel. 
              </li>
              
              <li>
                Por ocasião do término da participação do Contratante no Programa, desde que o término não seja motivado por inadimplemento contratual do Contratante e que este restitua os Equipamentos à Mobees na forma do item 4.5, acima, o Contratante receberá um pagamento final, proporcional ao número de dias decorridos no período mensal em que ocorrer o término do Programa em relação ao Contratante. O pagamento final descrito nesta cláusula será realizado até o dia 10 do mês subsequente à devolução dos Equipamentos em uma das bases da Mobees. 
                <ol>
                  <li>
                    Em caso de descumprimento do prazo para devolução dos Equipamentos, previsto no item 4.5, acima, o Contratante perderá o direito ao recebimento do pagamento proporcional previsto neste item.  
                  </li>
                    
                  <li>
                    Em caso de resilição pela vontade de qualquer das Partes, o pagamento final da remuneração será calculado até a data do envio da notificação do término do Programa à Mobees ou ao Contratante, conforme o caso. 
                  </li>  
                </ol>  
              </li>
              
              <li>
                Todos os pagamentos da remuneração do Programa serão realizados, pela Mobees, mediante o crédito de recursos em favor da conta bancária indicada pelo Contratante no Termo de Adesão, que poderá ser de titularidade do Contratante ou de terceiros. Eventuais alterações nos dados bancários do Contratante deverão ser comunicadas à Mobees com antecedência mínima de 10 (dez) dias da data em que houver previsão de pagamento da remuneração do Programa ao Contratante, sendo certo que serão considerados válidos e perfeitos todos os pagamentos realizados pela Mobees ao Contratante em conformidade com os dados bancários informados no Termo de Adesão, caso nenhuma alteração seja notificada tempestivamente à Mobees. 
              </li>
              
              <li>
                O Contratante será o único responsável pelo pagamento de todos os tributos incidentes sobre a remuneração por ele recebida em virtude do Programa, ficando a Mobees desde já isenta de qualquer responsabilidade pelo pagamento de tais tributos.
              </li>
            </ol>
          </li>
          <li>
            <strong>INDICAÇÃO DE MOTORISTAS</strong> 

            <ol>
              <li> 
                As condições para pagamento do prêmio por indicação são:

                <ol>
                  <li>
                     o Contratante precisa ser Parceiro Mobees.
                  </li>
                  <li>
                     o Contratante precisa ter mais de 4 meses de parceria em andamento com a Mobees.
                  </li>
                  <li>
                     o Contratante indicado também precisa ser Parceiros Mobees e ter mais de 4 meses de parceria em andamento.
                  </li>
                  <li>
                     o Contratante indicado precisa ter feito o cadastro com o link de indicação do Contratante.
                  </li>
                  <li>
                     o prêmio de R$ 100,00 é pago uma única vez, por cada indicação.
                  </li>
                </ol>
              </li>
            </ol>
          <li>
            <strong>PRAZO E RESCISÃO</strong> 

            <ol>
              <li>
                Estes Termos e Condições são firmados por prazo indeterminado e entram em vigor na data da assinatura do Termo de Adesão. A extinção destes Termos e Condições ocorrerá pela vontade de qualquer das Partes, a qualquer momento, por qualquer motivo ou sem motivo, com ou sem aviso prévio, por falta imputável a qualquer das Partes ou, ainda, por motivos de força maior, não imputáveis a qualquer das Partes, mediante o envio de simples notificação de Parte a Parte. A MOBEES RESERVA PARA SI O DIREITO DE RESILIR UNILATERALMENTE ESTES TERMOS E CONDIÇÕES, A QUALQUER MOMENTO, DE FORMA IMOTIVADA, MEDIANTE O ENVIO DE SIMPLES NOTIFICAÇÃO AO CONTRATANTE; NESTE CASO, O CONTRATANTE NÃO FARÁ JUS AO RECEBIMENTO DE QUALQUER INDENIZAÇÃO E DEVERÁ CUMPRIR O DISPOSTO NO ITEM 8.3.1, ABAIXO. 
              </li>
              
              <li>
                Estes Termos e Condições serão rescindidos e extintos automaticamente, independentemente do envio de aviso ou notificação de Parte a Parte, em casos de (i) violação das obrigações ora assumidas, por parte do Contratante; (ii) falsidade, imprecisão ou incorreção das declarações prestadas pelo Contratante, nos termos aqui previstos, ou das informações fornecidas pelo Contratante no Termo de Adesão; (iii) descumprimento dos Requisitos do Programa, por parte do Contratante; ou (iv) falecimento, interdição, declaração de ausência ou qualquer condenação criminal do Contratante. 
              </li>
              
              <li>
                Caso o Contratante deseje encerrar a sua participação no Programa, este deverá notificar a Mobees sobre a sua decisão por intermédio de um dos Canais de Comunicação, na forma do item 3.4, acima. Igualmente, a Mobees, desejando encerrar o Programa em relação ao Contratante, deverá notificá-lo da extinção destes Termos e Condições (i) por correio eletrônico (<em>e-mail</em>), enviado para o endereço do Contratante indicado no Termo de Adesão; ou (ii) por escrito, mediante protocolo de recebimento, em um dos endereços do Contratante descritos no Termo de Adesão.  
                <ol>
                  <li>
                    O Contratante, após ter recebido uma notificação da Mobees sobre a extinção destes Termos e Condições ou, conforme o caso, após ter enviado uma notificação para a Mobees a respeito da extinção destes Termos e Condições deverá, no prazo de até 2 (dois) dias contados do recebimento/envio da notificação, agendar visita a uma das bases da Mobees para remoção dos Equipamentos, na forma do item 4.5 acima, sendo que o não comparecimento do Contratante na data agendada ensejará cobrança da multa moratória prevista no mesmo item. Caso o agendamento não seja realizado, por qualquer motivo, o Contratante deverá conduzir o seu veículo diretamente até uma das bases da Mobees para remoção dos Equipamentos, independentemente de agendamento, no prazo de até 5 (cinco) dias contados do recebimento/envio da notificação, na forma do item 4.5, acima. A não adoção de uma das medidas descritas neste item, pelo Contratante, no prazo aqui previsto, sujeitará o Contratante ao pagamento da multa moratória prevista no item 4.5, acima. 
                  </li>
                </ol>
              </li>

              <li>
                As Partes não farão jus a qualquer indenização ou compensação pela resilição unilateral destes Termos e Condições, independentemente do motivo ou de aviso prévio, exceto pela violação de quaisquer das previsões contidas nestes Termos e Condições, caso em que serão aplicáveis as penalidades e indenizações previstas neste Termos e Condições.
              </li>
            </ol>
          </li>

          <li>
            <strong>INDENIZAÇÕES</strong> 
            <ol>
              <li>
                O Contratante será responsável por indenizar e manter indenes a Mobees, as empresas controladas, controladoras, coligadas ou colaterais da Mobees e os seus respectivos administradores, sócios, empregados, prepostos, agentes e mandatários, de todas e quaisquer perdas decorrentes de demandas, reclamações, condenações, penalidades, responsabilidades e despesas (inclusive honorários advocatícios), decorrentes ou relacionados: (i) à violação ou não cumprimento de qualquer parte destes Termos e Condições (incluindo os Requisitos do Programa), de qualquer lei ou regulamento aplicável, por parte do Contratante; (ii) à falsidade, incompletude ou imprecisão das declarações e garantias prestadas pelo Contratante nestes Termos e Condições; (iii) à reclamações de terceiros ou à disputas com terceiros, oriundos de atos do Contratante ou de fatos ocorridos durante o desempenho das atividades profissionais ou pessoais do Contratante, inclusive durante a sua participação no Programa; e (iv) à participação do Contratante no Programa, ao seu veículo ou ao seu comportamento ao dirigir, ressalvadas, em qualquer caso, as hipóteses de culpa exclusiva ou dolo da Mobees, conforme atestados em decisão judicial transitada em julgado.  
                <ol>
                  <li>
                    Para os fins do pagamento das indenizações devidas conforme o item 9.1, acima, a Mobees, ao receber um aviso, intimação, citação, ofício ou auto de infração emitido por uma autoridade pública ou judicial, relativo aos temas no mesmo item, deverá (i) notificar o Contratante a respeito do fato, para que o Contratante possa defender e indenizar a Mobees; e (ii) cooperar com a defesa da Mobees e do Contratante no processo ou procedimento em questão, seguindo quaisquer diretrizes definidas pelos assessores legais que patrocinarem esta defesa. Sem prejuízo, será facultado à Mobees, se assim o desejar, assumir a sua própria defesa no âmbito de processos ou procedimentos instaurados em relação aos temas descritos no item 9.1, acima, cabendo ao Contratado o ressarcimento dos custos e despesas de tal defesa (inclusive honorários advocatícios). 
                  </li>
                </ol>
              </li>

              <li>
                A Mobees será responsável por indenizar e manter o Contratante indene de todas e quaisquer perdas decorrentes de penalidades ou sanções aplicadas por autoridades públicas, ou de condenações judiciais sofridas, em virtude de uma suposta não conformidade dos Equipamentos ou do conteúdo publicitário exposto no Painel com as leis, regulamentos ou requisitos aplicáveis, ressalvadas, em qualquer caso, as hipóteses de culpa exclusiva ou dolo do Contratante, conforme atestados em decisão judicial transitada em julgado. Nestes casos, o Contratante, ao receber um aviso, intimação, citação, ofício ou auto de infração emitido por uma autoridade pública ou judicial, relativo aos temas descritos neste item, deverá (i) notificar prontamente a Mobees a respeito do fato, para que a Mobees possa defender e indenizar o Contratante, desde que este esteja cumprindo estes Termos e Condições e os Requisitos do Programa; e (ii) cooperar com a defesa da Mobees e do Contratante no processo ou procedimento em questão, seguindo quaisquer diretrizes relacionadas a essa defesa.  
                <ol>
                  <li>
                    Em nenhuma hipótese, a Mobees será responsável por danos indiretos, punitivos, especiais, exemplares, incidentais ou emergentes, ou por perda de receita ou lucros cessantes.  
                  </li>
                    
                  <li>
                    A responsabilidade da Mobees, decorrente ou relacionada com o Contrato, fica limitada a indenizar os danos diretos comprovadamente causados ao Contratante, relacionados aos temas descritos no item 9.2 acima e não excederá, em hipótese alguma, o valor da remuneração do Programa recebida pelo Contratante com relação à sua participação no Programa nos 12 (doze) meses que antecederem o evento ou circunstância que der origem à obrigação de indenizar. 
                  </li>
                </ol>
              </li>
            </ol>
          </li>
            
          <li>
            <strong>DISPOSIÇÕES GERAIS</strong> 
            <ol>
              <li>
                <span style="text-decoration:underline;">Relacionamento entre as Partes</span>. As Partes são e permanecerão sendo contratantes independentes, sem qualquer envolvimento ou relacionamento além do que se convenciona expressamente nestes Termos e Condições. Estes Termos e Condições e a participação do Contratante no Programa não criam e nem criarão qualquer relacionamento ou vínculo de natureza societária, empregatícia e/ou econômica entre as Partes, sendo certo que o Contratante é livre para definir o tempo e o modo como conduzirá as suas atividades profissionais, notadamente os serviços de táxi ou de transporte remunerado privado de passageiros ou entregas ofertados por uma das organizações elencadas no Anexo I destes Termos e Condições, bem como o Contratante é livre para decidir sobre a manutenção ou não do contrato firmado com tais entidades. Fica consignado pelas Partes que a Mobees não possui frota de veículos ou <em>softwares </em>e sistemas voltados para intermediação e facilitação de serviços de transporte de passageiros ou entregas, fazendo somente o comodato dos Equipamentos para fins de exibição de conteúdo publicitário dos Clientes Mobees, de acordo com estes Termos e Condições.  
              </li>
              
              <li>
                <span style="text-decoration:underline;">Tolerância</span>. A tolerância de qualquer Parte a eventual conduta em desacordo com estes Termos e Condições, quer seja um descumprimento contratual ou não, representará mera liberalidade, não podendo ser invocada pela Parte inadimplente para respaldar conduta similar em outro momento. De igual modo, o não exercício de um direito não impedirá a Parte titular do direito de exercê-lo a qualquer tempo. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Alterações</span>. A MOBEES RESERVA PARA SI O DIREITO DE REALIZAR ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES NESTES TERMOS E CONDIÇÕES, A QUALQUER MOMENTO, SEM A NECESSIDADE DE AVISO PRÉVIO AO CONTRATANTE. EM CASO DE ALTERAÇÕES DESTES TERMOS E CONDIÇÕES QUE RESTRINJAM DIREITOS DO CONTRATANTE, A MOBEES PODERÁ COMUNICAR TAL ALTERAÇÃO AO CONTRATANTE, AO SEU EXCLUSIVO CRITÉRIO, POR QUALQUER MEIO DE COMUNICAÇÃO DISPONÍVEL, SEJA FÍSICO OU ELETRÔNICO. TODAVIA, ESTA LIBERALIDADE NÃO AFASTA A RESPONSABILIDADE DO CONTRATANTE DE VERIFICAR PERIODICAMENTE EVENTUAIS MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES.  
                <ol>
                  <li>
                    CASO O CONTRATANTE NÃO CONCORDE COM ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES FUTURAS DESTES TERMOS E CONDIÇÕES, DEVERÁ CANCELAR PRONTAMENTE A SUA PARTICIPAÇÃO NO PROGRAMA E RESTITUIR A POSSE DOS EQUIPAMENTOS PARA A MOBEES, NA FORMA PREVISTA NESTES TERMOS E CONDIÇÕES. O FATO DE O CONTRATANTE CONTINUAR A UTILIZAR OS EQUIPAMENTOS APÓS A DIVULGAÇÃO OU COMUNICAÇÃO DE UMA MODIFICAÇÃO DOS TERMOS E CONDIÇÕES REPRESENTA O SEU CONSENTIMENTO EM MANTER-SE A ESTES TERMOS E CONDIÇÕES, TAL COMO ALTERADOS. 
                  </li>
                </ol>
              </li>

              <li>
                <span style="text-decoration:underline;">Acordo Integral</span>. Estes Termos e Condições (incluindo todos os seus anexos), juntamente com o Termo de Adesão e quaisquer outros acordos expressamente incorporados por referência a estes Termos e Condições, representam o entendimento e o acordo integral, completo e exclusivo entre o Contratante e a Mobees em relação à participação do Contratante no Programa. Estes Termos e Condições e o Termo de Adesão prevalecem sobre qualquer outro acordo, verbal ou escrito, previamente estabelecido. Estes Termos e Condições obrigam as Partes, os seus sucessores e cessionários autorizados. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Cessão</span>. Fica vedada, sendo nula de pleno direito, a cessão da posição contratual do Contratante sem prévia anuência, por escrito, da Mobees. Por outro lado, a Mobees poderá ceder estes Termos e Condições a qualquer momento, sem aviso prévio ou consentimento do Contratante. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Consentimento para Comunicações Eletrônicas</span>. Ao participar do Programa, o Contratante concorda em receber comunicações eletrônicas enviadas pela Mobees, inclusive para eventuais alterações destes Termos e Condições, conforme aqui estabelecido. O Contratante concorda, ainda, que quaisquer avisos, acordos, divulgações ou outras comunicações que lhe sejam enviados eletronicamente satisfarão todos os requisitos legais de comunicação, incluindo que essas comunicações sejam feitas por escrito, para todos os fins. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Legislação Aplicável</span>. Este Termos e Condições é regido exclusivamente pelas leis brasileiras.
              </li>
            </ol>
          </li>

          <li>
            <strong>FORO</strong> 
            <ol>
              <li>
                Fica eleito o foro da Comarca do Rio de Janeiro, Estado do Rio de Janeiro, para solucionar uma disputa entre as Partes.
              </li>
            </ol>
          </li>
        </ol>

        <h3 class="subtitle-1">
          ANEXO I
        </h3>
        <h4 class="subtitle-2 mb-4">
          Lista de Organizações Aprovadas
        </h4>
        <p>
          O Contratante poderá participar do Programa, desde que cumpra todos os seus Requisitos. Inclusive, o Contratante deverá participar costumeiramente de serviços de transporte remunerado privado de passageiros ou entregas, ofertados por uma das seguintes organizações:
        </p>
        <ul>
          <li>Uber</li>

          <li>99</li>

          <li>Cabify</li>

          <li>Taxi Rio</li>

          <li>Easy</li>

          <li>Wappa</li>
        </ul>

        <h3 class="subtitle-1 mt-8">
          ANEXO II
        </h3>
        <h4 class="subtitle-2 mb-4">
          Requisitos do Programa Motorista Mobees
        </h4>
        <p>
          Para participar do Programa, o Contratante deverá cumprir todos os Requisitos elencados neste Anexo II, às suas próprias expensas, de forma cumulativa, contínua e ininterrupta:
        </p>
        <ol>
          <li>
            Registrar-se no Programa, ser aprovado pela Mobees e assinar o Termo de Adesão, aderindo expressamente a estes Termos e Condições.
          </li>
          <li>
            Ser indivíduo maior de 21 (vinte e um) anos de idade, plenamente capaz para a assunção das obrigações previstas nestes Termos e Condições, bem como para o gozo de seus direitos civis, em geral.
          </li>
          <li>
            Participar costumeiramente de serviços de transporte remunerado privado de passageiros ou entregas, ofertados por uma das organizações elencadas no Anexo I destes Termos e Condições, como contratado independente de tais organizações.
          </li>
          <li>
            Não ter sido suspenso ou excluído anteriormente do Programa.
          </li>
          <li>
            Não estar impedido de exercer as suas atividades profissionais e/ou de participar no Programa, por força de leis, regulamentos, decisões judiciais, contratos e outros acordos aplicáveis, dos quais o Contratante seja parte ou aos quais o Contratante esteja sujeito.
          </li>
          <li>
            Não estar cumprindo pena de suspensão do direito de dirigir ou de cassação da Carteira Nacional de Habilitação, decorrente de crime de trânsito, e não estar impedido, judicialmente, de exercer seus direitos.
          </li>
          <li>
            Ter antecedentes criminais negativos, sem registros de delitos ou crimes cometidos na direção de veículos automotivos, ou que envolvam roubos, ameaça, violência ou drogas.
          </li>
          <li>
            Não ter registros de infrações por dirigir em velocidade igual ou superior a 20% (vinte por cento) do limite, nos últimos 3 (três) anos.
          </li>
          <li>
            Ter Carteira Nacional de Habilitação válida, emitida há mais de 3 (três) anos, com uma pontuação por infrações cometidas inferior a 10 (dez) pontos, e com permissão expressa para o exercício de atividade de transporte remunerada, após ter realizado e sido aprovado em avaliação psicológica.
          </li>
          <li>
            Possuir um veículo, próprio ou alugado, no modelo do ano de 2014 ou mais recente, com uma pintura de acabamento de fábrica, e usar apenas este veículo durante o Programa. O Contratante também poderá utilizar veículo de terceiros, desde o veículo atenda os Requisitos do Programa e apresente autorização assinada pelo proprietário.
          </li>
          <li>
            Obter e manter uma apólice de seguro de responsabilidade civil do veículo cadastrado no Programa, que atenda plenamente aos requisitos da organização em que o Contratante participa. 
          </li>
        </ol>

        <h3 class="subtitle-1">
          ANEXO III
        </h3>
        <h4 class="subtitle-2 mb-4">
          Descrição dos Equipamentos
        </h4>

        <p>
          Layout Externo do Equipamento
          <img
            class="mt-4"
            src="/img/tos-specs.png"
            width="100%"
            alt="Ilustração do Equipamento"
          >
        </p>

        <p>
          Lista de componentes:
        </p>
        <ul class="mb-8">
          <li>12 telas de led p3.33</li>
          <li>1 antena GPS</li>
          <li>1 gabinete preto de alumínio</li>
          <li>2 antenas para adaptador de wifi</li>
          <li>2 telas de policarbonato</li>
          <li>1 sensor de ajuste automático de brilho</li>
          <li>3 fontes de energia</li>
          <li>1 mini modem</li>
          <li>1 unidade de processamento</li>
          <li>cabos e fios elétricos e de dados</li>
          <li>1 placa de vídeo</li>
          <li>1 rack para carro</li>
        </ul>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="grey"
          text
          @click="close"
        >
          Voltar
        </v-btn>
        <!-- <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click="acceptTOS(true)"
        >
          Aceitar

        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 90% !important;
  }

  .terms {
    border-radius: 0 !important;
  }

  .terms ol { 
    padding: 1rem 0 0;
    list-style-position: outside;
    counter-reset: item;
  }
  .terms ol li { 
    display: inline-block;
    padding-bottom: 1rem;
  }
  .terms ol>li:before {
    font-weight: 700;
    content: counters(item, ".") ". ";
    counter-increment: item;
  }
  .terms ol ul {
    padding: 1rem 0 0 1rem;
    list-style-position: outside;
    list-style-type: square !important;
  }
  .terms ol ul>li:before {
    content: '■ ';
  }

</style>

<script>
  // Utilities
  import {
    mdiClose
  } from '@mdi/js'
  import { get, sync } from 'vuex-pathify'
  import services from '@/services.js'

  export default {
    name: 'Tos',

    data: () => ({
      icons: {
        mdiClose
      },
      btnAccept: {
        disabled: false,
        loading: false
      }
    }),

    computed: {
      accepted: sync('app/views@tos.accepted'),
      toggle: sync('app/views@tos.toggle'),
      // user: sync('user'),
      // toast: sync('app/toast'),
    },

    methods: {
      ...services,

      close () {
        this.toggle = false;
        if (this.$route.name=='Termos de Uso') this.$router.push('/');
      },

      acceptTOS (b) {
        this.accepted = b;
        this.close();
      }

    },

    mounted () {
    },

  }
</script>
